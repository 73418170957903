@import 'scss-imports/cssvars';
@import 'mixins/text';
@import 'mixins/animations';

/* TYPOGRAPHY */

p { margin-bottom: 1.15rem; }

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.15;
  margin: 2.75rem 0 1.05rem;
  margin: 0;
}

h1 {
  color: var(--fg1);
  font-size: 2.488em;
  margin-top: 0;
}

h1,
h2,
h3 {
  font-family: var(--font-family-header);
  font-weight: 500;
}

h4,
h5 {
  font-family: var(--font-family-body);
  font-weight: 400;
}

h2 {font-size: 2.074em;}

h3 {font-size: 1.728em;}

h4 {font-size: 1.44em;}

h5 {font-size: 1.2em;}

small {font-size: 0.833em;}

span.code {
  background: rgba(255, 255, 255, 0.1);
  font-family: var(--font-family-monospace);
  opacity: 0.75;
}

/* MENUS */
.mat-mdc-menu-item {
  height: 36px !important;
  line-height: 36px !important;
}

.mat-mdc-menu-item.sticky-bottom,
.mat-mdc-option.sticky-bottom {
  background: var(--bg2);
  border-top: solid 1px var(--lines);
  bottom: 0;
  position: sticky;
}

.mat-mdc-menu-item.sticky-top,
.mat-mdc-option.sticky-top {
  background: var(--bg2);
  border-bottom: solid 1px var(--lines);
  position: sticky;
  top: 0;
}

/* Cards */
.mat-mdc-card.mat-mdc-card-padded {
  padding: 16px 32px !important;
}

.form-card.mat-mdc-card {
  margin: 0 auto;
  max-width: 960px;
}

.mat-mdc-card > .no-padding {
  padding: 0 !important;
}

.mat-mdc-card-title-text {
  margin: 0 !important;
}

.mat-toolbar .mat-mdc-button {
  height: 36px !important;
  margin: 0 4px;
}

/*********************************************************************/
/* Dematerialize Form Fields :) */
.form-element {
  margin: 16px 0 17px;
  text-align: left;
}

.form-element .label.input-text,
.form-element .label.input-select {
  font-size: x-small;
}

.form-element .label-container {
  align-items: center;
  display: flex;
  height: 24px;
  margin-bottom: 4px;
  padding: 4px 0;

  label {
    margin-right: 8px;
  }
}

/* Bring back the native placeholder */
input.mat-input-element::placeholder,
textarea.mat-input-element::placeholder {
  color: var(--fg2) !important;
  opacity: 0.5;
  -webkit-text-fill-color: var(--fg2) !important;
}

.mat-divider {
  background: var(--lines) !important;
  opacity: 1 !important;
}

.mat-divider-horizontal {
  border-top-color: var(--lines) !important;
}

.fieldset {
  padding-bottom: 24px;
}

.fieldset.empty-fieldset {
  padding-bottom: 0;
}

.full-width {
  position: relative;
}

.form-element .mat-mdc-text-field-wrapper,
.form-element input[type = 'password'],
.form-element input[type = 'email'],
.form-element input[type = 'text'],
.form-element input[type = 'number'],
.form-element textarea,
.form-element select {
  background-color: var(--bg1);
  border: solid 1px var(--lines);
  box-sizing: border-box;
  color: var(--fg1);

  &[disabled] {
    cursor: not-allowed;
    opacity: var(--disabled-opacity);
  }
}

.form-element,
#password,
.form-element .mat-mdc-text-field-wrapper,
.mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-chip-grid,
.mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-chip-grid input,
.form-element .form-button,
.form-element input[type = 'password'],
.form-element input[type = 'email'],
.form-element input[type = 'text'],
.form-element input[type = 'number'],
.form-element input[type = 'search'],
.form-element textarea,
.form-element select {
  border-radius: 0;
  line-height: normal !important;
  min-height: 33px;
  width: 100% !important;
}

/* Chips */
.form-element .mat-mdc-chip {
  font-size: smaller !important;
}

.mat-mdc-chip-set .mdc-evolution-chip-set__chips {
  max-width: 100%;
}

.form-element .mat-mdc-form-field-suffix {
  top: -2px;
}

.form-element textarea {
  max-width: 100%;
  min-width: 100%;

  .no-rows {
    min-height: 96px;
  }
}

.mat-mdc-checkbox {
  margin-left: -10px;
  margin-right: 8px;
  opacity: 0.75;
}

ix-tooltip button {
  i,
  ix-icon {
    opacity: 0.5;
  }
}

.label-container ix-tooltip {
  margin: 0;
}

.tooltip .material-icons {
  font-size: 16px !important;
}

/* Normalize mat-select */
.mat-mdc-form-field-label-wrapper,
.mat-mdc-form-field-underline,
.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-infix {
  border: 0;
  height: fit-content;
  overflow: hidden;
  padding: 0;
}

/* Buttons */
.mat-mdc-button,
.mat-button,
.mat-flat-button {
  border-radius: 0 !important;
  font-weight: 400 !important;

  &:focus-visible {
    outline: 1.5px solid var(--alt-fg2) !important;
  }
}

.mat-mdc-button[disabled] span.mdc-button__label,
.mat-flat-button[disabled] span.mdc-button__label {
  opacity: 0.5;
}

.mat-mdc-card-actions .mat-mdc-button,
.mat-mdc-card-actions .mat-flat-button {
  background: var(--btn-default-bg) !important;
  background-color: var(--btn-default-bg) !important;
  color: var(--fg2) !important;
}

.mat-mdc-button,
.mat-flat-button,
button.mat-mdc-button.btn-default,
button.mat-mdc-button.mat-default,
.mat-mdc-card-actions button.mat-default,
button.mat-default,
button.mat-mdc-button.mat-accent.btn-default,
button.mat-mdc-button[type='button']:not(button.mat-primary) {
  background: var(--btn-default-bg);
  background-color: var(--btn-default-bg);
  color: var(--fg2) !important;
}

button.mat-mdc-button[type='button']:hover,
button.mat-mdc-button.mat-accent[type='button']:hover {
  background-color: var(--alt-bg1) !important;
  color: var(--fg2) !important;
}

a.mat-mdc-button.mat-primary,
button.mat-mdc-button.mat-primary {
  background-color: var(--primary) !important;
  color: var(--primary-txt) !important;
  opacity: 1;
}

a.mat-mdc-button.mat-primary:focus-visible,
button.mat-mdc-button.mat-primary:focus-visible {
  background: var(--primary-lighter) !important;
  box-shadow: none !important;
  outline: 1.5px solid var(--grey);
  outline-offset: 0;
}

button.mat-mdc-button.mat-accent {
  background-color: var(--accent) !important;
  color: var(--accent-txt) !important;
  opacity: 1 !important;
}

button.mat-mdc-button.mat-error,
button.mat-mdc-button.mat-red,
button.mat-mdc-button.mat-warn {
  background-color: var(--red) !important;
  color: #efefef !important;
  opacity: 1 !important;
}

button.mat-mdc-button.mat-finished,
button.mat-mdc-button.mat-green {
  background-color: var(--green) !important;
  color: #efefef !important;
  opacity: 1 !important;
}

/* Menus */

button.mat-mdc-menu-trigger.menu-toggle.mat-mdc-button {
  max-height: 36px;
}

.mat-mdc-option:hover:not(.mat-mdc-option-disabled),
.sidenav-link:hover,
.slide-in-nav-item:hover,
.mat-mdc-menu-item:hover,
.mat-mdc-button:hover {
  background-color: var(--hover-bg) !important;
}

/* Nested Cards */
.mat-mdc-card .form-card.mat-mdc-card {
  border: none;
  box-shadow: unset;
}

/* Material Cards*/
body .mat-mdc-card {
  border: solid 1px var(--contrast-lighter);
  font-family: var(--mdc-dialog-subhead-font);
  padding: 0 !important;
  position: relative;
}

#ix-slide-in-form .mat-mdc-card,
#slide-in-form .mat-mdc-card {
  border: none;
  box-shadow: none;
}

#ix-slide-in-form .ix-form-container,
#slide-in-form .ix-form-container {
  padding: 0;
}

#ix-chained-slide-in-form .mat-mdc-card,
.slide-in2-form .mat-mdc-card {
  border: none;
  box-shadow: none;
}

#ix-chained-slide-in-form .ix-form-container,
.slide-in2-form .ix-form-container {
  padding: 0;
}

.mat-mdc-card .mat-toolbar-row,
body .mdc-card__actions,
.mat-mdc-card .mat-mdc-card-content > div,
.mat-mdc-card .mat-mdc-card-footer {
  padding: 16px;
}

.mat-mdc-card-content {
  padding: 0 !important;
}

.mat-mdc-card .mat-toolbar-row {
  border-bottom: solid 1px var(--lines);
  flex-wrap: wrap;
  gap: 8px;
  height: initial;
  justify-content: space-between;
  padding: 8px 16px;

  .toolbar-row-title {
    align-items: center;
    display: flex;

    > a {
      @include line-clamp(2);
      max-width: 75%;
    }
  }

  .mat-card-title-text {
    margin: 0;
  }

  h3.mat-card-title-text {
    @media(max-width: $breakpoint-sm) {
      margin: 0 auto;
      max-width: 75%;
      white-space: pre-wrap;
      word-break: break-word;
      z-index: 0;
    }
  }
}

.mat-mdc-card .mat-toolbar-row .actions {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: flex-end;
  margin-left: auto;
}

.mat-mdc-card .mat-mdc-card-footer .actions button {
  margin-right: 8px;
}

.mat-mdc-card .mat-mdc-card-footer {
  margin-top: 8px;
  padding: 0 16px;
  width: 100%;
}

/* Material Tables */

table.mat-mdc-table {
  background-color: var(--bg2) !important;
  font-family: var(--mdc-dialog-subhead-font);
  width: 100%;
}

th.mat-mdc-table-sticky,
.mat-mdc-table thead th {
  background-color: var(--contrast-darker) !important;
  background-color: rgba(0, 0, 0, 0.1);
  vertical-align: middle;
}

tr.mat-header-row,
.mat-mdc-table thead tr {
  height: 48px;
  max-height: 48px;
}

.mat-mdc-row,
.mat-header-row,
.mat-footer-row,
th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  border-bottom-color: var(--lines) !important;
}

mat-row,
mat-header-row,
mat-footer-row,
th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  color: var(--fg2) !important;
  position: relative;
  vertical-align: middle;
}

.mat-mdc-table-sticky {
  position: sticky !important;
}

td.mat-mdc-table-sticky:hover,
td.mat-mdc-table-sticky {
  background: unset !important;
}

td.mat-cell.mat-mdc-table-sticky.hover::before {
  max-width: unset;
}

td.mat-cell.mat-mdc-table-sticky {
  background-color: var(--bg2) !important;
  overflow: hidden;
}

td.mat-cell.mat-mdc-table-sticky::before {
  content: '';
  display: block;
  height: 1000px;
  left: 0;
  max-height: inherit;
  max-width: inherit;
  position: absolute;
  top: 0;
  width: 1000px;
  z-index: -1;
}

td.mat-cell.tree-cell {
  max-width: 300px;
}

td.mat-cell .ix-icon {
  top: calc(50% - 12px);
}

td.mat-cell .ix-icon.expansion-trigger {
  font-size: 24px;
}

td.mat-cell.mat-mdc-table-sticky.hover::before,
td.mat-cell.mat-mdc-table-sticky .hover,
.mat-mdc-row:hover,
tr.mat-mdc-row:hover {
  background: rgba(0, 0, 0, 0.1) !important;
}

/* Material Lists */
.mat-mdc-list-item {
  border-bottom: solid 1px var(--lines) !important;
}

.value,
.mat-mdc-list-item .value {
  font-weight: 500;
}

.label,
.mat-mdc-list-item .label {
  margin-right: 8px;
}

mat-card.mat-mdc-card .mat-mdc-list-base {
  padding-top: 0;
}

/* Expansion Panel Fixes */
.mat-expansion-panel-header span.mat-content {
  padding-top: 4px;
  vertical-align: middle !important;
}

.mat-expansion-panel-header span.mat-expansion-indicator {
  margin-top: -4px;
  position: relative;
  right: 9px;
}

.mat-expansion-panel.no-padding .mat-expansion-panel-body {
  padding: 0;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

ix-tooltip {
  popper-content {
    display: none;
  }
}

.ngxp__container {
  border-radius: 6px !important;

  &[aria-hidden='false'] {
    display: block !important;
  }

  &[data-popper-placement=right] {
    margin-left: 13px !important;
  }

  &[data-popper-placement=top] {
    margin-left: 11px !important;
  }

  &[data-popper-placement=bottom] {
    margin-left: 11px !important;
  }
}

@media #{$media-lt-sm} {
  .mat-mdc-button {
    font-size: 12px;
    min-width: auto;
    padding: 0 8px;
  }
}

.mat-mdc-tab-header-pagination-chevron {
  border-color: unset !important;
  color: var(--fg2);
}

.mat-mdc-tab-header-pagination {
  background-color: var(--lines);
  opacity: 0.6;
}

.mat-mdc-tab-header-pagination-disabled {
  opacity: 0.2;
}

.mdc-tab {
  flex-grow: initial !important;

  .mdc-tab__content {
    font-family: var(--mdc-dialog-subhead-font);
    font-size: 14px;
    font-weight: 500;
  }

  &.mdc-tab--active {
    color: var(--primary);

    .mdc-tab__text-label {
      color: var(--primary);
    }
  }
}

ix-root {
  .warning {
    color: var(--orange);
  }

  .error,
  .error-warning {
    color: var(--error);
  }

  %focused-shared {
    box-shadow: 0 0 0 1.5px var(--focus-bg) inset !important;
    outline-width: 0;
  }

  .cdk-focused,
  .cdk-keyboard-focused,
  .cdk-mouse-focused {
    @extend %focused-shared;
    background-color: var(--focus-bg) !important;
  }

  .ix-tree-node:focus-visible,
  .ix-tree-node:focus,
  ix-app-row:focus-visible,
  ix-app-row:focus,
  .ix-nested-tree-node > div:focus-visible {
    @extend %focused-shared;
  }
}

// needed to correctly put box shadow on :focus-visible
ix-tree-node,
ix-nested-tree-node > div {
  padding: 1px;
}

.mdc-text-field--outlined .mdc-notched-outline {
  display: none;
}

.mat-mdc-paginator-container {
  min-height: 56px;
}

.mat-mdc-chip {
  font-weight: 500;
}

mat-chip-grid {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

body {
  .mdc-tooltip {
    white-space: pre-line;
    word-break: break-word;

    .mdc-tooltip__surface {
      background-color: #666;
      color: #fff;
      font-size: 10px;
      line-height: 16px;
      padding: 6px 8px;
    }
  }

  .mat-mdc-input-element {
    background-color: transparent;
    border: 0;
    color: var(--fg2);
    font-size: 12px;
    outline: none !important;
    width: 100%;
  }

  .mat-mdc-list-item {
    border-bottom: solid 1px var(--lines);
  }

  .mat-mdc-menu-item {
    height: inherit !important;
    min-height: inherit;
  }

  .mdc-linear-progress__buffer-bar {
    background-color: var(--primary);
    opacity: 0.5;
  }

  .mdc-linear-progress__bar-inner {
    border-color: var(--primary);
  }

  .mat-mdc-slide-toggle {
    font-family: var(--mdc-dialog-subhead-font);
  }

  .mat-mdc-option {
    line-height: 15px;
    min-height: 36px;
    padding: 3px 8px;
  }

  ix-checkbox {
    ix-errors {
      .mat-error {
        margin-top: 0 !important;
      }
    }
  }

  .mat-mdc-checkbox label {
    display: flex;
    flex: 1 0 auto;
    padding-left: 0;
  }

  .mdc-data-table__cell,
  .mdc-data-table__header-cell {
    border-color: var(--lines);
  }

  .mdc-data-table__header-row,
  .mdc-data-table__row {
    height: 48px;
    min-height: 48px;
  }

  .mat-column-action {
    overflow: visible;
  }

  .mdc-data-table__header-cell {
    font-size: 12px;
  }

  .mdc-data-table__cell ul {
    padding-left: 12px;
  }

  .mdc-data-table__row:last-child .mdc-data-table__cell {
    border-bottom: 1px solid var(--lines);
  }

  .mdc-evolution-chip-set .mdc-evolution-chip {
    margin-bottom: 4px;
    margin-top: 4px;
  }

  .mdc-checkbox__background {
    transform: scale(0.9);
  }

  ix-dynamic-form-item.highlighted > div:first-child {
    outline: 1.5px solid var(--primary);
  }

  ix-fieldset.highlighted fieldset {
    outline: 1.5px solid var(--primary);
  }

  .search-element-highlighted {
    @include pulse-box-shadow();

    background-color: var(--contrast-darkest) !important;
    box-shadow: none;
    outline: 0;
    overflow: visible;

    @media (prefers-reduced-motion) {
      &::after {
        animation: none;
      }
    }
  }
}

body .mat-calendar-previous-button::after,
body .mat-calendar-next-button::after {
  margin: 10px;
}

.mat-mdc-menu-item {
  > span {
    align-items: center;
    display: flex;
    width: 100%;
  }
}

.cdk-overlay-pane.mat-mdc-dialog-panel {
  @media(max-width: $breakpoint-xs) {
    max-width: 95% !important;
    width: inherit;
  }
}

body .mat-vertical-content {
  @media(max-width: $breakpoint-sm) {
    padding: 0 8px 8px;
  }
}

ix-table,
table {
  overflow: auto;

  @media(max-width: $breakpoint-md) {
    tr th {
      width: 78px;
    }
  }

  ul li {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}

mat-list-item {
  height: inherit;
  min-height: 48px;

  ul {
    max-width: 100%;
    white-space: pre-wrap;
    width: fill-available;
    word-wrap: break-word;
  }

  span {
    flex-wrap: wrap;
    max-width: fill-available;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}

.highlighted-element {
  outline: 1.5px solid var(--primary) !important;
  transition: 0.3s ease all;
}

@media(max-width: $breakpoint-sm) {
  .mdc-dialog__container,
  mat-dialog-container {
    max-width: 100% !important;
  }
}

div.mat-mdc-autocomplete-panel.mat-mdc-autocomplete-visible {
  margin: 5px 0;
}

mat-tab-nav-panel {
  .search-element-highlighted {
    display: block;
  }
}
