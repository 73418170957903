@mixin line-clamp($lines: 2) {
  -webkit-box-orient: vertical;
  /* stylelint-disable-next-line */
  display: -webkit-inline-box;
  -webkit-line-clamp: #{$lines};
  line-clamp: #{$lines};
  overflow: hidden;
  white-space: normal;
  word-break: break-word;
}
