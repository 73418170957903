// Use CSS custom properties for palette colors
// via this mixin.
@mixin variable($property, $variable, $fallback, $important:null) {
  #{$property}: $fallback $important;
  #{$property}: var($variable) $important;
}

// Solarized Color Palette - don't forget to add accents to theme.service!
$bg1: #171e26; // Canvas like behind the cards
$bg2: #242424; // Main elements like cards
// $bg2:    #232d35; // Main elements like cards
$fg1: #aaa; // brighter fonts
$fg2: #ccc; // normal fonts
$alt-bg1: rgba(122, 122, 122, 0.25); // Tables
$alt-bg2: #6f6e6c; // Menus
$alt-fg1: #c1c1c1; // Tables
$alt-fg2: #e1e1e1; // Menus
$yellow: #f0cb00;
$orange: #ee9302;
$red: #ff0013;
$magenta: #d238ff;
$violet: #c17ecc;
$blue: #0d5788;
$cyan: #00d0d6;
$green: #1f9642;

// Generated txt colors
$yellow-txt: #333;
$orange-txt: #eee;
$red-txt: #fff;
$magenta-txt: #fff;
$violet-txt: #fff;
$blue-txt: #fff;
$cyan-txt: #333;
$green-txt: #fff;
$primary-txt: #fff;
$accent-txt: #333;

$primary: $blue;
$accent: $yellow;
$warn: $red;
$tooltip: 'inline';//display property
$toggle-pw-display-prop: inline;
$enable-warning: inline;

$font-family-body: 'IBM Plex Sans';

// Logo images
$logo: url('assets/images/light-logo.svg');
$logo-text: url('assets/images/light-logo-text.svg');

$md-primary: (
  50 : #e0f2fa,
  100 : #b3dff2,
  200 : #80caea,
  300 : #4db5e2,
  400 : #26a5db,
  500 : #0095d5,
  600 : #008dd0,
  700 : #0082ca,
  800 : #0078c4,
  900 : #0067ba,
  A100 : #e3f1ff,
  A200 : #b0d7ff,
  A400 : #7dbdff,
  A700 : #63b0ff,
  contrast: (
    50 : #000,
    100 : #000,
    200 : #000,
    300 : #000,
    400 : #000,
    500 : #fff,
    600 : #fff,
    700 : #fff,
    800 : #fff,
    900 : #fff,
    A100 : #000,
    A200 : #000,
    A400 : #000,
    A700 : #000,
  )
);

$md-accent: (
  50 : #fff8e1,
  100 : #ffecb5,
  200 : #ffe083,
  300 : #ffd451,
  400 : #ffca2c,
  500 : #ffc107,
  600 : #ffbb06,
  700 : #ffb305,
  800 : #ffab04,
  900 : #ff9e02,
  A100 : #fff,
  A200 : #fffaf2,
  A400 : #ffe4bf,
  A700 : #ffd9a6,
  contrast: (
    50 : #000,
    100 : #000,
    200 : #000,
    300 : #000,
    400 : #000,
    500 : #000,
    600 : #000,
    700 : #000,
    800 : #000,
    900 : #000,
    A100 : #000,
    A200 : #000,
    A400 : #000,
    A700 : #000,
  )
);

$breakpoint-xs: 600px;
$breakpoint-sm: 960px;
$breakpoint-md: 1280px;
$breakpoint-lg: 1920px;

$media-xs: 'screen and (max-width: #{$breakpoint-xs - 1})';
$media-sm: 'screen and (min-width: #{$breakpoint-xs}) and (max-width: #{$breakpoint-sm - 1})';
$media-md: 'screen and (min-width: #{$breakpoint-sm}) and (max-width: #{$breakpoint-md - 1})';
$media-lg: 'screen and (min-width: #{$breakpoint-md}) and (max-width: #{$breakpoint-lg - 1})';
$media-xl: 'screen and (min-width: #{$breakpoint-lg})';
$media-lt-sm: 'screen and (max-width: #{$breakpoint-xs - 1})';
$media-lt-md: 'screen and (max-width: #{$breakpoint-sm - 1})';
$media-lt-lg: 'screen and (max-width: #{$breakpoint-md - 1})';
$media-lt-xl: 'screen and (max-width: #{$breakpoint-lg - 1})';
$media-gt-xs: 'screen and (min-width: #{$breakpoint-xs})';
$media-gt-sm: 'screen and (min-width: #{$breakpoint-sm})';
$media-gt-md: 'screen and (min-width: #{$breakpoint-md})';
$media-gt-lg: 'screen and (min-width: #{$breakpoint-lg})';
