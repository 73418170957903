@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
    url('../../fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: local('Roboto Italic'), local('Roboto-Italic'), url('../../fonts/Roboto/Roboto-Italic.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
    url('../../fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
    url('../../fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
    url('../../fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local('Roboto Thin'), local('Roboto-Thin'), url('../../fonts/Roboto/Roboto-Thin.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url('../../fonts/Roboto/Roboto-Light.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url('../../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url('../../fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url('../../fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local('Roboto Black'), local('Roboto-Black'), url('../../fonts/Roboto/Roboto-Black.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
    url('../../fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
}
