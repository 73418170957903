// TODO: Do something better.
html .ix-dark {
  --mdc-text-button-label-text-tracking: normal;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-line-height: 1.65;
  --mdc-list-list-item-label-text-color: var(--fg2);
  --mdc-list-list-item-disabled-label-text-color: var(--fg2);
  --mdc-list-list-item-hover-label-text-color: var(--fg2);
  --mdc-list-list-item-label-text-font: var(--font-family-body2);
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-selected-container-color: var(--bg2);
  --mdc-list-list-item-supporting-text-color: var(--fg2);

  --mat-card-title-text-tracking: normal;

  --mdc-dialog-subhead-color: var(--fg1);
  --mdc-dialog-supporting-text-color: var(--fg2);
  --mdc-dialog-supporting-text-font: var(--font-family-body);
  --mdc-dialog-supporting-text-size: 0.875rem;
  --mdc-dialog-subhead-size: 1.42rem;
  --mdc-dialog-subhead-font: 'Roboto', 'Helvetica Neue', sans-serif;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-subhead-weight: 600;
  --mdc-dialog-supporting-text-line-height: 1.65;
  --mdc-dialog-subhead-line-height: 1.65;
  --mdc-dialog-supporting-text-tracking: 0;
  --mdc-dialog-container-shape: 0;
  --mdc-dialog-subhead-tracking: 0;
  --mdc-shape-medium: 4px;
  --mdc-theme-primary: var(--primary);
  --mdc-switch-selected-handle-color: var(--primary);
  --mdc-switch-selected-track-color: var(--primary);
  --mdc-switch-selected-hover-handle-color: var(--primary);
  --mdc-switch-selected-hover-track-color: var(--primary);
  --mdc-switch-selected-focus-handle-color: var(--primary);
  --mdc-switch-selected-focus-track-color: var(--primary);
  --mdc-switch-selected-pressed-handle-color: var(--primary);
  --mdc-switch-selected-pressed-track-color: var(--primary);
  --mat-radio-label-text-color: var(--fg1);
  --mdc-form-field-label-text-color: var(--fg1);
  --mdc-form-field-label-text-size: inherit;
  --mdc-switch-unselected-track-color: var(--alt-bg2);
  --mdc-switch-unselected-hover-handle-color: var(--alt-bg1);
  --mdc-switch-unselected-hover-track-color: var(--alt-bg2);
  --mdc-switch-unselected-focus-handle-color: var(--alt-bg1);
  --mdc-switch-unselected-handle-color: var(--alt-bg1);
  --mdc-switch-unselected-focus-track-color: var(--alt-bg2);
  --mdc-switch-unselected-pressed-handle-color: var(--alt-bg1);
  --mdc-switch-unselected-pressed-track-color: var(--alt-bg2);
  --mdc-switch-unselected-icon-color: var(--primary-txt);
  --mdc-switch-selected-icon-color: var(--primary-txt);
  --mdc-switch-handle-surface-color: var(--alt-bg1);
  --mdc-circular-progress-active-indicator-color: var(--primary);
  --mat-mdc-radio-checked-ripple-color: var(--primary);
  --mat-mdc-radio-ripple-color: var(--primary);
  --mdc-radio-selected-icon-color: var(--primary);
  --mdc-radio-unselected-icon-color: var(--primary);
  --mdc-radio-selected-hover-icon-color: var(--primary);
  --mdc-radio-selected-pressed-icon-color: var(--primary);
  --mdc-radio-unselected-hover-icon-color: var(--primary);
  --mdc-radio-unselected-pressed-icon-color: var(--primary);
  --mdc-radio-unselected-focus-icon-color: var(--primary);
  --mdc-radio-selected-focus-icon-color: var(--primary);
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #000000de;
  --mdc-chip-label-text-color: #000000de;
  --mdc-chip-container-height: 23px;
  --mdc-snackbar-container-color: var(--bg1);
  --mdc-snackbar-supporting-text-color: var(--primary-txt);
  --mdc-snackbar-supporting-text-font: var(--mdc-dialog-supporting-text-font);
  --mdc-snackbar-supporting-text-size: 1rem;
  --mat-option-selected-state-layer-color: var(--bg2);
  --mat-mdc-slider-hover-ripple-color: rgba(0, 149, 213, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(0, 149, 213, 0.2);
  --mat-mdc-button-persistent-ripple-color: rgba(0, 0, 0, 0.2);
  --mdc-icon-button-icon-color: var(--fg2);
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.2);
  --mdc-checkbox-state-layer-size: 40px;
  --mat-tab-header-inactive-label-text-color: var(--fg2);
  --mat-tab-header-inactive-hover-label-text-color: var(--fg1);
  --mat-tab-header-inactive-focus-label-text-color: var(--fg1);
  --mat-table-row-item-label-text-color: var(--fg2);
  --mat-table-header-headline-color: var(--fg2);
  --mdc-filled-text-field-input-text-color: var(--alt-fg2);
  --mat-option-selected-state-label-text-color: var(--fg1);
  --mat-menu-item-label-text-line-height: 36px;
  --mat-menu-item-label-text-font: var(--font-family-body2);
  --mat-paginator-container-size: unset;
  --mat-paginator-container-text-font: var(--font-family-body2);

  --mat-stepper-header-selected-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-weight: 500;
  --mat-stepper-container-text-font: var(--font-family-body2);
  --mat-stepper-header-label-text-font: var(--font-family-body2);
  --mat-stepper-header-label-text-size: 14px;

  --mat-checkbox-label-text-color: var(--fg1);
  --mdc-checkbox-unselected-icon-color: var(--primary);
  --mdc-checkbox-selected-checkmark-color: var(--fg1);
  --mdc-checkbox-selected-focus-icon-color: var(--primary);
  --mdc-checkbox-selected-hover-icon-color: var(--primary);
  --mdc-checkbox-selected-icon-color: var(--primary);
  --mdc-checkbox-selected-pressed-icon-color: var(--primary);
  --mdc-checkbox-selected-focus-state-layer-color: var(--primary);
  --mdc-checkbox-selected-hover-state-layer-color: var(--primary);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--primary);

  --mdc-outlined-button-label-text-tracking: normal;
  --mdc-outlined-button-label-text-size: 0.95rem;

  --mdc-filled-text-field-container-color: transparent;

  --mat-form-field-container-vertical-padding: 0;
  --mat-paginator-container-text-line-height: 12px;
  --mat-form-field-container-height: 0;

  --mdc-filled-button-container-color: transparent;
  --mdc-filled-button-label-text-color: var(--fg2);
  --mat-toolbar-title-text-tracking: normal;
  --mdc-outlined-button-label-text-color: var(--fg2);

  --mat-autocomplete-background-color: var(--bg2);

  .mat-mdc-slide-toggle {
    --mat-switch-label-text-color: var(--fg2);
  }

  .mat-mdc-radio-button .mat-internal-form-field {
    --mat-radio-label-text-color: var(--fg1);
  }

  .mat-mdc-slide-toggle,
  .mat-mdc-checkbox {
    --mdc-form-field-label-text-color: var(--fg1);
    --mdc-form-field-label-text-tracking: normal;
    --mdc-form-field-label-text-size: inherit;
    --mdc-form-field-label-text-font: var(--font-family-body2);
  }

  .mat-mdc-slide-toggle {
    --mdc-form-field-label-text-color: var(--fg2);
  }

  .mat-mdc-checkbox {
    --mdc-form-field-label-text-font: var(--font-family-body2);

    &.mat-mdc-checkbox-disabled label {
      color: var(--grey);
    }

    &.mat-primary {
      --mdc-checkbox-selected-icon-color: var(--primary);
      --mdc-checkbox-unselected-icon-color: var(--primary);
      --mdc-checkbox-selected-hover-icon-color: var(--primary);
      --mdc-checkbox-unselected-hover-icon-color: var(--primary);
      --mdc-checkbox-selected-pressed-icon-color: var(--primary);
      --mdc-checkbox-unselected-pressed-icon-color: var(--primary);
      --mdc-checkbox-selected-focus-state-layer-color: var(--primary);
      --mdc-checkbox-unselected-focus-state-layer-color: var(--primary);
      --mdc-checkbox-selected-hover-state-layer-color: var(--primary);
      --mdc-checkbox-unselected-hover-state-layer-color: var(--primary);
      --mdc-checkbox-disabled-selected-icon-color: #9c9c9c;
      --mdc-checkbox-disabled-unselected-icon-color: #9c9c9c;
      --mdc-checkbox-unselected-focus-icon-color: var(--fg2);
    }
  }

  .mat-mdc-radio-button {
    --mdc-form-field-label-text-color: var(--fg2);
    --mdc-form-field-label-text-size: 12px;

    &.mat-primary {
      --mdc-radio-disabled-selected-icon-color: var(--primary);
      --mdc-radio-disabled-unselected-icon-color: var(--primary);
      --mdc-radio-unselected-hover-icon-color: var(--primary);
      --mdc-radio-unselected-icon-color: var(--primary);
      --mdc-radio-state-layer-size: 36px;
    }
  }

  .mat-button-toggle-button {
    font-family: var(--font-family-body2);
  }

  .mat-mdc-select {
    --mat-select-trigger-text-line-height: 1.65;
  }

  .mat-mdc-chip-input {
    padding: 0;
  }

  .mat-mdc-chip.mat-mdc-standard-chip {
    --mdc-chip-container-height: unset;
    --mdc-chip-label-text-size: 11px;
  }

  .mat-mdc-button.mat-accent {
    --mat-text-button-state-layer-color: var(--accent);
    --mat-text-button-ripple-color: var(--alt-bg1);
  }

  .mat-mdc-tab-nav-bar {
    --mat-tab-header-inactive-label-text-color: var(--fg2);
    --mat-tab-header-inactive-hover-label-text-color: var(--fg1);
    --mat-tab-header-active-ripple-color: var(--focus-bg);
    --mat-tab-header-inactive-ripple-color: var(--focus-bg);
    --mat-tab-header-inactive-focus-label-text-color: inherit;
  }

  .mat-mdc-tab-link {
    --mat-tab-header-label-text-tracking: normal;
  }

  .mat-mdc-standard-chip {
    --mdc-chip-label-text-weight: 600;
  }

  .mat-mdc-select-arrow svg {
    fill: var(--fg2);
  }

  .mat-mdc-slide-toggle .mdc-switch--disabled+label {
    color: var(--fg2);
    opacity: var(--disabled-opacity);
  }
}
