/* CSS Vars */
:root {
  --fn-dropshadow: 0 1px 2px rgba(0, 0, 0, 0);
  --contrast-normal: var(--bg2);
  --contrast-darker: hsl(0, 0%, 10.7%); // default theme fallback
  --contrast-darkest: hsl(0, 0%, 5.7%); // default theme fallback
  --contrast-lighter: hsl(0, 0%, 20.7%); // default theme fallback
  --contrast-lightest: hsl(0, 0%, 25.7%); // default theme fallback
  --light-theme-lines: var(--contrast-darkest);
  --dark-theme-lines: var(--contrast-lighter);
  --disabled-opacity: 0.5;
  --lines: var(--dark-theme-lines);
  --error: #f44336;
  --sidenav-width: 240px;
  --btn-default-bg: var(--alt-bg2);
  --hover-bg: var(--lines) !important;
  --primary-lighter: var(--primary);
  --font-family-header: 'Titillium Web', sans-serif;
  --font-family-body: 'IBM Plex Sans', 'Roboto' , 'Helvetica Neue' ,sans-serif;
  --font-family-monospace: 'Droid Sans Mono', 'Courier New', Courier, monospace;

  // TODO: Standartize on one or rename to something more descriptive?
  --font-family-body2: 'Roboto' , 'Helvetica Neue' ,sans-serif;
  --primary-txt: #fff;
}
