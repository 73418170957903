@import 'scss-imports/cssvars';

@mixin blink {
  animation: blinker 0.75s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.1;
  }
}

@mixin fade-in {
  animation-duration: 0.35s;
  animation-fill-mode: backwards;
  animation-name: fade-in-animation;
}

@keyframes fade-in-animation {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

%pulse-box-shadow {
  animation: pulse-box-shadow-animation 2s infinite;
  border-radius: inherit;
  bottom: 0;
  box-shadow: none;
  left: 0;
  outline: 0;
  right: 0;
  top: 0;
}

@mixin pulse-box-shadow {
  position: relative;

  &.mdc-button,
  &[class^='mat-'] {
    &:focus {
      background-color: var(--contrast-darkest) !important;
    }

    &::after {
      @extend %pulse-box-shadow;

      content: '';
      position: absolute;
    }
  }

  &:not(&.mdc-button) {
    @extend %pulse-box-shadow;
  }
}

@keyframes pulse-box-shadow-animation {
  0%,
  100% {
    box-shadow: none;
    outline: 0;
  }

  50% {
    box-shadow: 0 0 10px 10px var(--primary-lighter);
    outline: 2px solid var(--primary);
  }
}
